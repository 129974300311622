<!-- 搜索内容 -->
<template>
  <div class="_sosuo">
    <div class="sousuoss">
      <div class="anniu" @click="shuchu" v-if="value != '1'">
        <img src="@/assets/img/new/shang.png" alt="" class="shizhe" />
      </div>
      <div class="anniu" @click="xinxi" v-else>
        <img src="@/assets/img/new/shang.png" alt="" class="shizhe" />
      </div>
      <input
        type="text"
        v-model="shuru"
        class="kksr"
        placeholder="请输入您要搜索的内容"
        @keydown="handleFocus"
      />
      <div class="xiala">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 表格 -->
    <div class="waikuang">
      <div class="table_tou">
        <div class="fontone one">类型</div>
        <div class="fontone two">标题</div>
        <div class="fontone three">办理时间</div>
      </div>
      <div class="table_shen">
        <div class="hang" v-for="(item, index) in list" :key="index">
          <div @click="tiaoru(item.cardsalt)" class="dian" v-if="value != '1'"></div>
          <div @click="tiaoru(item.sharesalt)" class="dian" v-else></div>
          <div class="fonttwo one">{{ item.type_txt }}</div>
          <div class="fonttwo two">
            {{ item.title }}
          </div>
          <div v-if="value != 1" class="fonttwo three">
            {{ item.articletime }}
          </div>
          <div v-else class="fonttwo three">
            {{ item.createtime.slice(0, 10) }}
          </div>
        </div>
      </div>
    </div>
    <!-- 页码 -->
    <div style="min-height: 30px; width: 1200px">
      <div class="yema" v-show="total > 15">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :page-sizes="[15, 30]"
          :page-size="100"
          layout=" prev, pager, next, sizes, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
import API from "@/api/modules/list.js";
import JK from "@/api/modules/infos.js";
export default {
  data() {
    return {
      shuru: "",
      sou: null,
      list: [],
      loading: null, //等待效果
      page: 1,
      pageLine: 15,
      total: 0,
      options: [
        {
          value: "0",
          label: "帖子",
        },
        {
          value: "1",
          label: "动态",
        },
      ],
      value: "0",
    };
  },
  watch: {
    value: function (vla, old) {
      if (vla == "0") {
        this.shuchu();
      } else {
        this.xinxi();
      }
    },
  },
  mounted() {
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
    this.sou = JSON.parse(localStorage.getItem("sou"));
    this.shuru = this.sou.hunt;
    this.value = String(this.sou.stu);
    if (this.sou.stu == 0) {
      this.shuchu();
    } else {
      this.xinxi();
    }
  },
  methods: {
    // 跳转至详情
    tiaoru(id) {
      if (this.value == 0) {
        this.$store.state.loginMessage.cardsalt = id;
        // localStorage.setItem("cardsalt", id);
        localStorage.setItem("cardsalttt", id);
        let times = setTimeout(() => {
          let routeData = this.$router.resolve({
            name: "details",
            query:{
              'cardsalt':id
            } 
          });
          // this.$store.state.loginMessage,
          window.open(routeData.href, "_blank");
        }, 100);
      } else {
        this.$store.state.loginMessage.wid = id;
        window.localStorage.setItem("xin", 1);
        const routeData = this.$router.resolve({
          path: "/infosdetail",
          query: {
            'wid':id
          }
        });
        // this.$store.state.loginMessage,
        window.open(routeData.href, "_blank");
      }
    },
    // 信息
    async xinxi() {
      let a = {
        stu: this.value,
        hunt: this.shuru,
      };
      localStorage.setItem("sou", JSON.stringify(a));
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await JK.share({
        search: this.shuru,
        page: this.page,
        pageLine: this.pageLine,
        type: 4,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
      this.loading.close();
    },
    // 帖子
    async shuchu() {
      let a = {
        stu: this.value,
        hunt: this.shuru,
      };
      localStorage.setItem("sou", JSON.stringify(a));
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.articleCardList({
        sinfo: this.shuru,
        kind: 0,
        classif: 0,
        type: 3,
        artstime: 0,
        departid: "",
        genres: 0,
        page: this.page,
        pageLine: this.pageLine,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
      this.loading.close();
    },
    // 输入框聚焦监听
    handleFocus(event) {
      if (event.key === "Enter") {
        // 处理Enter键的逻辑
        let a = {
          stu: this.value,
          hunt: this.shuru,
        };
        localStorage.setItem("sou", JSON.stringify(a));
        this.page = 1
        if (this.value == "0") {
          this.shuchu();
        } else {
          this.xinxi();
        }
      }
    },
    // 切换条目
    handleSizeChange(val) {
      this.pageLine = val;
      this.shuchu();
      // console.log(`每页 ${val} 条`);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      this.shuchu();
      window.scrollTo(0, 0);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped lang="scss">
._sosuo {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 36px;
}
.sousuoss {
  width: calc(876px - 94.29px);
  height: 60px;
  background: rgba(250, 250, 250, 0.28);
  position: relative;
  display: flex;
  .kksr {
    width: 88%;
    height: 100%;
    border: 1px solid #2c89eb;
    border-radius: 4px;
    background: transparent;
    outline: none;
    padding-left: 33px;
    font-family: PingFangSC-Regular;
    font-size: 22px;
    color: #202020;
    letter-spacing: 0;
    font-weight: 400;
    padding-right: 33px;
    border-right: 0px solid;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .kksr::placeholder {
    font-family: PingFangSC-Regular;
    font-size: 22px;
    color: #202020;
    letter-spacing: 0;
    font-weight: 400;
  }
  .anniu {
    width: 94.29px;
    height: 60px;
    position: absolute;
    right: -92.29px;
    top: 0px;
    z-index: 10;
    background-image: linear-gradient(114deg, #88e4b6 0%, #306ef5 100%);
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .shizhe {
    width: 33.78px;
    height: 34.88px;
  }
  .xiala {
    flex: 1;
    border: 1px solid #2c89eb;
    border-radius: 4px;
    border-left: 0px solid;
    height: 60px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep(.el-input__inner) {
      background-color: transparent !important;
      border: 0px solid #dcdfe6 !important;
      font-family: PingFangSC-Regular;
      font-size: 18px !important;
      color: #202020 !important;
      font-weight: 400;
    }
  }
}
.waikuang {
  width: 1200px;
  margin-top: 36px;
  .table_tou {
    width: 100%;
    height: 58px;
    background-image: linear-gradient(180deg, #d1e1f9, #f0f6fe);
    display: flex;
  }
  .table_shen {
    width: 100%;
    .hang {
      width: 100%;
      height: 58px;
      display: flex;
      cursor: pointer;
      position: relative;
    }
    .dian {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 20;
    }
    .hang:nth-child(even) {
      background: #f1f7ff;
    }
  }
}
.one {
  flex: 0.8;
}
.two {
  flex: 8;
}
.three {
  flex: 1.2;
}
.fontone {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #262729;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 58px;
  text-align: center;
}
.fonttwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #262729;
  letter-spacing: 0;
  line-height: 58px;
  font-weight: 400;
  text-align: center;
}
.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: flex-end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}
</style>
  